export interface RiskCategoryDTO {
  id: number;
  title_en: string;
  title_ar: string;
  status: string;
}
export interface RiskCategoryDetailsDTO {
  id: number;
  title: string;
  status: string;
}

export interface RiskCategoryFormDTO {
  id?: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export function convertResponseToRiskCategoryDetailsDTO(
  response: any,
  lang: string
): RiskCategoryDetailsDTO | null {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    title: response[`title_${lang}`],
    status: response.status,
  };
}
